import { atom } from 'recoil';

export interface IAuthState {
  isLogged: boolean;
  email?: string;
  id?: number;
  image?: string;
  name?: string;
}

export const authState = atom<IAuthState>({
  key: 'authState',
  default: {
    isLogged: false,
  },
});
