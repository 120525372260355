import React from 'react';
import { Spin } from 'antd';
import styled from '@emotion/styled';

const ContentLoading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function OfficeContentLoading() {
  return (
    <ContentLoading>
      <Spin />
    </ContentLoading>
  );
}

export default OfficeContentLoading;
